import React from 'react';
import _ from 'lodash';
import {graphql} from 'gatsby';

import {Layout} from '../components/index';
import Header from '../components/Header';
import {htmlToReact} from '../utils';
import Footer from '../components/Footer';

import Slideshow from '../components/Slideshow';
import { MDXRenderer } from "gatsby-plugin-mdx";

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($locale: String!, $slug: String!) {
    sitePage(path: {eq: $slug}) {
      id
    }
    mdx(
      fields: { locale: { eq: $locale } }
      frontmatter: { slug: { eq: $slug } }
    ) {
      id
      body
      frontmatter {
        title
        subtitle
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        slideshows {
          title
          text_before
          text_after
          datas {
            alt
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        seo {
          extra {
            keyName
            name
            value
            relativeUrl
          }
          description
          robots
          title
        }
        og_image {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        twitter_image {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;

const PageSlideShow = props => {
  return (
      <Layout {...props}>
        <Header {...props} site={props.pageContext.site} page={props.pageContext} />
        <div id="content" className="site-content">
          <main id="main" className="site-main inner">
            <article className="post page post-full">
              <header className="post-header">
                <h1 className="post-title">{_.get(props, 'data.mdx.frontmatter.title', null)}</h1>
              </header>
              {_.get(props, 'data.mdx.frontmatter.subtitle', null) && (
              <div className="post-subtitle">
                {htmlToReact(_.get(props, 'data.mdx.frontmatter.subtitle', null))}
              </div>
              )}
              <Slideshow slideshowDatas={props.data.mdx.frontmatter.slideshows[0]} />
              <Slideshow slideshowDatas={props.data.mdx.frontmatter.slideshows[1]} />
              <Slideshow slideshowDatas={props.data.mdx.frontmatter.slideshows[2]} />
              <Slideshow slideshowDatas={props.data.mdx.frontmatter.slideshows[3]} />
              <Slideshow slideshowDatas={props.data.mdx.frontmatter.slideshows[4]} />
              <Slideshow slideshowDatas={props.data.mdx.frontmatter.slideshows[5]} />
              <div className="post-content">
                <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
              </div>
            </article>
          </main>
          <Footer {...props} site={props.pageContext.site} page={props.pageContext} image={_.get(props, 'data.mdx.frontmatter.img_path', null)} />
        </div>
      </Layout>
  );
}

export default PageSlideShow
